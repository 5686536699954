import React from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Impressum = () => {
  return (
    <article className="wrapper">
      <NavBar current="contact"/>
      <div className="mx-auto lg:text-left lg:w-3/4 mt-8 px-4 md:px-6 lg:px-8 max-w-screen-xl pb-28">
        <h1 className="text-2xl">Impressum</h1>

        <p className="adsimple-121890947">Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63
          Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
        <p className="adsimple-121890947">Mobility Leasing GmbH<br/>Europastrasse 5, <br/>6322 Kirchbichl, <br/>Österreich
        </p>
        <p className="adsimple-121890947">
          <strong>UID-Nummer:</strong> ATU 63395659<br/>
          <strong>Firmensitz:</strong> 6322 Kirchbichl</p>
        <p className="adsimple-121890947">
          <strong>Telefon:</strong> 0043-5372-64993
        </p>
        <p className="adsimple-121890947">
          <strong>E-Mail:</strong> <a href="mailto:info@moblity-leasing.at" className="text-blue-500 underline">info@moblity-leasing.at</a>
        </p>
        <p className="adsimple-121890947">
          <strong>Verleihungsstaat:</strong> Österreich</p>
        <h2 className="text-xl" id="eu-streitschlichtung">EU-Streitschlichtung</h2>
        <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
          über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br/>
          Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
          Kommission unter <a className="adsimple-121890947"
                              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
                              target="_blank" rel="noopener">http://ec.europa.eu/odr?tid=121890947</a> zu richten. Die
          dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
        <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <h2 className="text-xl" id="haftung-fuer-inhalte-dieser-webseite">Haftung für Inhalte dieser
          Website</h2>
        <p>Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle Informationen
          bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website
          übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht
          verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen
          zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
        <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach
          den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle
          unserer Nichtverantwortlichkeit davon unberührt.</p>
        <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu
          kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im
          Impressum.</p>
        <h2 className="text-xl" id="haftung-links-webseite" >Haftung für Links auf dieser Website</h2>
        <p>Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich sind. Haftung für
          verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben,
          uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn
          uns Rechtswidrigkeiten bekannt werden.</p>
        <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden
          die Kontaktdaten im Impressum.</p>
        <h2 className="text-xl" id="urheberrechtshinweis" >Urheberrechtshinweis</h2>
        <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie
          uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf
          anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der
          Inhalte unserer Seite rechtlich verfolgen.</p>
        <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
          kontaktieren.</p>
        <h2 className="text-xl" id="bildernachweis" >Bildernachweis</h2>
        <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
        <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
        <ul className="adsimple-121890947">
        </ul>
        <p>Alle Texte sind urheberrechtlich geschützt.</p>
        <p>Quelle: Erstellt mit dem <a href="https://www.adsimple.at/impressum-generator/"
                                                               title="Impressum Generator von AdSimple für Österreich">Impressum
          Generator</a> von AdSimple</p>

      </div>
      <Footer />
    </article>
  );
};

export default Impressum;